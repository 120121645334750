import { useDispatch, useSelector } from 'react-redux';
import { personItemActions } from 'src/store/persons/item/actions';
import {
  selectPerson,
  selectPersonSessionFavoriteIds,
  selectPersonSpeakerFavoriteIds,
  selectPersonLoading,
} from 'src/store/persons/item/selectors';


const usePersonState = () => ({
  person: useSelector(selectPerson),
  personSessionFavoriteIds: useSelector(selectPersonSessionFavoriteIds),
  personSpeakerFavoriteIds: useSelector(selectPersonSpeakerFavoriteIds),
  isPersonLoading: useSelector(selectPersonLoading),
});

const usePersonDispatch = () => {
  const dispatch = useDispatch();

  return ({
    getPerson: () => dispatch(personItemActions.fetch()),
    updatePerson: person => dispatch(personItemActions.updatePerson(person)),
  });
};

export { usePersonState, usePersonDispatch };

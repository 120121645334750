const selectSessionId = state => state.router.location.query.sessionId || state.router.location.query.sessionid;
const selectSessionItem = state => state.session.item.entity;
const selectSession = state => selectSessionItem(state)
    && (selectSessionItem(state)?.parent || selectSessionItem(state)?.session);
const selectLiveStream = state => selectSession(state)?.liveStream?.live_stream;
const selectRecordings = state => selectLiveStream(state)?._recordings;
const selectIsRecordingsExist = state => (selectLiveStream(state)?._recordings || []).length > 0;
const selectLastRecording = state => {
  const recordings = (selectLiveStream(state)?._recordings || []);

  return recordings[recordings.length - 1];
};
const selectSessionCommentMailNotificationOverwrite = state => selectSession(state)
  ?.sessionCommentMailNotificationOverwrite;
const selectOnDemand = state => selectSession(state)?.onDemand?.on_demand;
const selectSessionItemLoading = state => state.session.item.loading > 0;

export { selectSessionId, selectSessionItem, selectSession, selectLiveStream,
  selectRecordings, selectOnDemand, selectSessionItemLoading, selectIsRecordingsExist,
  selectLastRecording, selectSessionCommentMailNotificationOverwrite };

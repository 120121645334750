import React, { useState } from 'react';
import moment from 'moment';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import BEM from 'bem-class-names-builder';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/view/components/Modal';
import { useSearchParamsState } from 'src/store/searchParams/hooks';
import { useAccessState } from 'src/store/access/hooks';
import { useInitState } from 'src/store/init/hooks';
import { usePersonState, usePersonDispatch } from 'src/store/persons/item/hooks';
import { useCountryListState } from 'src/store/countries/list/hooks';
import { getCountryName } from 'src/utils/country';
import { getFormattedTimeRange } from 'src/utils/date';
import { BOOKMARK, BOOKMARK_ACTIVE, MORE_DETAIL_BUTTON, SHARE } from 'src/constants/template';
import { ACCESS_ACCOUNT, ROLE_PARTICIPANT } from 'src/constants/access';
import { ShareModal } from 'src/view/pages/Sessions/Item/components/ShareModal';
import './styles.less';


const bem = new BEM('op-calendar');

const Event = ({
  hoursRangeLength, timelineItemSize, timelineStart, timelineEnd, start_date_time, playerUrl, _self,
  end_date_time, calendarItemView, topics, track, legacyId, session_type, premium, timeFormat, id,
}) => {
  const { colorBy } = useSearchParamsState();
  const { onlineprogram, congressDateFormat } = useInitState();
  const { person, personSessionFavoriteIds } = usePersonState();
  const { updatePerson } = usePersonDispatch();
  const { accessStatus, accessRoles } = useAccessState();
  const { countryList } = useCountryListState();
  const { t } = useTranslation();

  const [isShareModal, setIsShareModal] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const parentElementSize = (hoursRangeLength - 1) * timelineItemSize;

  const agendaStartDate = moment(timelineStart);
  const agendaEndDate = moment(timelineEnd);

  const heightForMinute = parentElementSize / agendaEndDate.diff(agendaStartDate, 'minutes');

  const eventStartDate = agendaStartDate.isAfter(start_date_time) ? agendaStartDate : moment(start_date_time);
  const eventEndDate = agendaEndDate.isBefore(end_date_time) ? agendaEndDate : moment(end_date_time);
  const eventDuration = moment.duration(eventEndDate.diff(eventStartDate)).asHours();

  const style = {
    top: (eventStartDate.diff(agendaStartDate, 'minutes') * heightForMinute),
    height: eventEndDate.diff(eventStartDate, 'minutes') * heightForMinute,
  };
  const isFavorite = personSessionFavoriteIds.includes(id);

  const currentPlayerUrl = `${playerUrl || onlineprogram?.defaultPlayerUrl}?sessionId=${id}`;

  const time = getFormattedTimeRange(eventStartDate, eventEndDate, timeFormat);
  const date = start_date_time.format(congressDateFormat?.short);

  const adjustedCalendarItemView = calendarItemView && parse(calendarItemView, {
    replace: ({ attribs, children, name }) => {
      if (attribs?.['data-op-replacement']?.includes('op-session-detail-page-url')) {
        const attributes = attributesToProps({
          ...attribs,
          href: `${onlineprogram?.sessionDetailPageUrl}?sessionId=${id}`,
        });

        return React.createElement(name, attributes, domToReact(children));
      }

      if (attribs?.['data-op-replacement']?.includes('op-session-time') || attribs?.class?.includes('replaceable-time')) {
        const attributes = attributesToProps(attribs);

        return React.createElement(name, attributes, time);
      }

      if (attribs?.['data-op-replacement']?.includes('op-session-date') || attribs?.class?.includes('replaceable-date')) {
        const attributes = attributesToProps(attribs);

        return React.createElement(name, attributes, date);
      }

      if (
        attribs?.['data-op-replacement']?.includes('op-session-type-translation')
        || attribs?.class?.includes('op-session__session-type-name')
      ) {
        const attributes = attributesToProps(attribs);
        const value = session_type?.id ? t(`op-session-type-${session_type?.id}`) : children;

        return React.createElement(name, attributes, value);
      }

      if (attribs?.['data-op-replacement']?.includes('op-session-change-bookmark') || attribs?.class?.includes(BOOKMARK)) {
        const attributes = attributesToProps(attribs);

        const classNames = isFavorite
          ? `${attribs.class} ${BOOKMARK_ACTIVE}`
          : attribs.class;

        return React.createElement(
          name,
          {
            ...attributes,
            title: t(attributes.title),
            class: classNames,
            onClick: () => updatePerson({
              _id: person._id,
              sessionFavorites: isFavorite
                ? person.sessionFavorites.filter(({ $ref }) => $ref !== _self.$ref)
                : [...person.sessionFavorites, { $ref: _self.$ref }],
            }),
          },
          accessStatus === ACCESS_ACCOUNT && accessRoles.includes(ROLE_PARTICIPANT) ? domToReact(children) : '',
        );
      }

      if (attribs?.['data-op-replacement']?.includes('op-session-show-share-modal') || attribs?.class?.includes(SHARE)) {
        const attributes = attributesToProps(attribs);

        return React.createElement(
          name,
          { ...attributes, title: t(attributes.title), onClick: () => setIsShareModal(true) },
          domToReact(children));
      }

      if (attribs?.['data-op-replacement']?.includes('op-country-name') || attribs?.class?.includes('op-country-code')) {
        const attributes = attributesToProps(attribs);
        const countryName = getCountryName(countryList, children);

        return React.createElement(name, attributes, countryName);
      }

      return null;
    },
  });

  const getCalendarViewSnippet = className =>
    adjustedCalendarItemView?.find(elem => elem.props?.className?.includes(className));

  const getItemSize = duration => {
    if (duration <= 0.5) return 'small';
    if (duration <= 1) return 'medium';

    return 'big';
  };

  const onShowModal = event => {
    const elementClassList = event.target.classList;
    const parentElementClassList = event.target.parentElement.classList;
    const bookmark = elementClassList.contains(BOOKMARK);
    const bookmarkActive = elementClassList.contains(BOOKMARK_ACTIVE);

    if (bookmark && !bookmarkActive) {
      elementClassList.add(BOOKMARK_ACTIVE);
    } else if (bookmark && bookmarkActive) {
      elementClassList.remove(BOOKMARK_ACTIVE);
    }

    if (elementClassList.contains(MORE_DETAIL_BUTTON) || parentElementClassList.contains(MORE_DETAIL_BUTTON)) {
      event.preventDefault();
      setIsVisibleModal(true);
    }

    if (!bookmarkActive && (bookmark || parentElementClassList.contains(BOOKMARK))) {
      event.preventDefault();
      updatePerson({
        _id: person._id,
        sessionFavorites: [...(person.sessionFavorites || []), { $ref: _self.$ref }],
      });
    }

    if (bookmarkActive || parentElementClassList.contains(BOOKMARK_ACTIVE)) {
      event.preventDefault();
      updatePerson({
        _id: person._id,
        sessionFavorites: (person.sessionFavorites || []).filter(({ $ref }) => $ref !== _self.$ref),
      });
    }

    if (elementClassList.contains(SHARE) || parentElementClassList.contains(SHARE)) {
      event.preventDefault();
    }
  };

  return (
    <div
      className={bem.elem('op-event').mix(
        !!topics?.length && `topic-${topics.map(topic => topic.id).join('-')}`,
        !!topics?.length && `topic-${topics[0]?.id}`,
        track?.id && `track-${track?.id}`,
        session_type?.id && `session_type-${session_type?.id}`,
        `session-${legacyId}`,
        (premium ? [...premium] : []),
        colorBy && `color-by-${colorBy}`,
        `op-session-size-${getItemSize(eventDuration)}`,
      ).toString()}
      style={style}
      onClick={onShowModal}
    >
      <div className={bem.elem(`item__${getItemSize(eventDuration)}`).toString()}>
        {getCalendarViewSnippet('session-body')}
      </div>
      <ShareModal
        title={t('shareTheEvent')}
        visible={isShareModal}
        handleClose={() => setIsShareModal(false)}
        value={onlineprogram?.sessionDetailPageUrl
          ? `${onlineprogram?.sessionDetailPageUrl}?sessionId=${id}`
          : currentPlayerUrl
        }
      />
      <Modal
        scrollable
        visible={isVisibleModal}
        handleClose={() => setIsVisibleModal(false)}
      >
        {getCalendarViewSnippet('session-popup')}
      </Modal>
    </div>

  );
};

export { Event };

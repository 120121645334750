import React from 'react';
import ReactDOM from 'react-dom';
import { useConfigs } from 'src/hooks/useConfigs';
import { SessionPlayerInit } from './components/SessionPlayerInit';


const SessionPlayer = () => {
  const { isNEOSPlugin } = useConfigs();
  const domElement = document.getElementById('op-session__player');

  return isNEOSPlugin
    ? domElement && ReactDOM.createPortal(<SessionPlayerInit />, domElement)
    : <SessionPlayerInit />;
};

export { SessionPlayer };

import { config } from 'src/configs';


const checkIfRefIsPartOfEvaService = ref => typeof ref === 'string' && ref.includes(config.api.services.eva);

const selectPerson = state => state.person.item.entry;
const selectPersonSessionFavorites = state => selectPerson(state)?.sessionFavorites || [];
const selectPersonSpeakerFavorites = state => selectPerson(state)?.speakerFavorites || [];
const selectPersonSessionFavoriteIds = state => (selectPersonSessionFavorites(state) || [])
  .map(({ $ref }) => checkIfRefIsPartOfEvaService($ref) && $ref.split('/').reverse()[0])
  .filter(Boolean);
const selectPersonSpeakerFavoriteIds = state => (selectPersonSpeakerFavorites(state) || [])
  .map(({ $ref }) => checkIfRefIsPartOfEvaService($ref) && $ref.split('/').reverse()[0])
  .filter(Boolean);
const selectPersonLoading = state => state.person.item.loading > 0;

export {
  selectPerson,
  selectPersonSessionFavoriteIds,
  selectPersonSpeakerFavoriteIds,
  selectPersonLoading,
};

import React, { useRef, useEffect, useState } from 'react';
import { VideoPlayer } from 'src/view/components/VideoPlayer';
import { usePersonState } from 'src/store/persons/item/hooks';
import { useSessionItemState } from 'src/store/sessions/item/hooks';
import { useSessionWatchTimeDispatch } from 'src/store/sessionWatchTime/hooks';


let interval;

const SessionPlayerInit = () => {
  const { session, liveStream, isRecordingsExist, lastRecording, onDemand } = useSessionItemState();
  const { person } = usePersonState();
  const { createSessionWatchTime } = useSessionWatchTimeDispatch();

  const videoPlayer = useRef(null);
  const [poster, setPoster] = useState(null);
  const [playbackUrl, setPlaybackUrl] = useState(null);

  const isNotCurrentPlaybackUrl = url => playbackUrl !== url;

  useEffect(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const priority of (session?.playerOptions?.mediaPriority || [])) {
      if (priority === 'liveStream' && liveStream?.isLive && isNotCurrentPlaybackUrl(liveStream?.playbackUrl)) {
        setPlaybackUrl(liveStream?.playbackUrl);

        break;
      }

      if (priority === 'recordings' && isRecordingsExist && isNotCurrentPlaybackUrl(lastRecording?.url)) {
        setPlaybackUrl(lastRecording?.url);

        break;
      }

      if (priority === 'onDemand' && onDemand?.url && isNotCurrentPlaybackUrl(onDemand?.url)) {
        setPlaybackUrl(onDemand?.url);

        break;
      }

      if (
        priority === 'liveStream'
        && isNotCurrentPlaybackUrl(liveStream?.playbackUrl)
        && isNotCurrentPlaybackUrl(lastRecording?.url)
        && isNotCurrentPlaybackUrl(onDemand?.url)
      ) {
        setPlaybackUrl(liveStream?.playbackUrl);
      }
    }

    setPoster(session?.playerOptions?.poster?.default);
  }, [session]);

  useEffect(() => {
    if (playbackUrl) {
      videoPlayer.current.load(playbackUrl);
    }

    if (playbackUrl && liveStream?.isLive) {
      videoPlayer.current.play();
    }
  }, [playbackUrl]);

  useEffect(() => {
    const playerState = videoPlayer.current?.getState();

    if (playerState === 'Ended') {
      setPoster(session?.playerOptions?.poster?.stop);
    }

    if (playerState === 'PlayerError' || playerState === 'PlayerNetworkUnavailable') {
      setPoster(session?.playerOptions?.poster?.failure);
    }

    if (!interval && session?._self && person?._self) {
      let lastPlayerState;

      interval = setInterval(() => {
        const currentPlayerState = videoPlayer.current?.getState();

        if (currentPlayerState === 'Playing' && lastPlayerState === 'Playing') {
          createSessionWatchTime({
            person: person?._self,
            session: session?._self,
            mode: liveStream?.isLive ? 'live-stream' : 'on-demand',
            startTime: 0,
            watchedDuration: 10,
          });
        }

        lastPlayerState = currentPlayerState;
      }, 10000);
    }
  }, [videoPlayer.current, session, person]);

  return (
    <VideoPlayer
      videoPlayerRef={videoPlayer}
      poster={poster}
    />
  );
};

export { SessionPlayerInit };

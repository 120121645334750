import moment from 'moment';
import { getDaysRange } from 'src/utils/date';


const getPreviousDay = day => moment(day).subtract(1, 'days').toISOString();
const getNextDay = day => moment(day).add(1, 'days').toISOString();


const generateFilterQuery = query => ({
  mode: 'raw',
  operator: 'OR',
  items: [
    {
      filter: query,
    },
  ],
});

const generateQuery = ({ viewType, ...searchParams }) => {
  const { isFilterDisabled, favorites, activeDay, congressStartDay, congressEndDay, filters, ...params } = searchParams;
  const combinedFilters = [];

  if (favorites?.length) {
    combinedFilters.push(generateFilterQuery({
      terms: {
        id: favorites,
      },
    }));
  }

  if (activeDay) {
    const previousDay = searchParams?.search ? getPreviousDay(congressStartDay) : getPreviousDay(activeDay);
    const nextDay = searchParams?.search ? getNextDay(congressEndDay) : getNextDay(activeDay);
    const days = searchParams?.search ? getDaysRange(congressStartDay, congressEndDay) : [activeDay];

    combinedFilters.push({
      mode: 'raw',
      operator: 'OR',
      items: [previousDay, ...days, nextDay].map(day => ({
        filter: {
          term: {
            start_date: day,
          },
        },
      })),
    });
  }

  if (!isFilterDisabled && filters) {
    Object.entries(filters).map(([name, filter]) => combinedFilters.push(({ name, ...filter })));
  }

  return combinedFilters.length ? { ...params, filters: combinedFilters } : params;
};

export { generateQuery };

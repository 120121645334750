import React, { Fragment, useState } from 'react';
import BEM from 'bem-class-names-builder';
import { useTranslation } from 'react-i18next';
import { Input, Button, Checkbox } from 'antd';
import { useSessionCommentsItemDispatch } from 'src/store/sessionComments/item/hooks';
import { useConfigs } from 'src/hooks/useConfigs';
import { useInitState } from 'src/store/init/hooks';
import { useSessionItemState } from 'src/store/sessions/item/hooks';
import './styles.less';


const bem = new BEM('comment-input');

/**
 * Component for creating a new comment and sending comment to specific session
 *
 * @category Main/Components
 */
const CommentsInput = () => {
  const { t } = useTranslation();

  const [content, setContent] = useState('');
  const [step, setStep] = useState('input-field-step');
  const [isNotification, setIsNotification] = useState(false);

  const { sessionId } = useConfigs();
  const { createComment } = useSessionCommentsItemDispatch();
  const { sessionCommentMailNotificationDefault } = useInitState();
  const { sessionCommentMailNotificationOverwrite } = useSessionItemState();

  const shouldBeMailNotification = () => {
    if (typeof sessionCommentMailNotificationOverwrite === 'boolean') {
      return sessionCommentMailNotificationOverwrite;
    }
    if (typeof sessionCommentMailNotificationDefault === 'boolean') {
      return sessionCommentMailNotificationDefault;
    }
    return false;
  };

  const inputField = (
    <div className={bem.elem('input-field').toString()}>
      <Input
        className={bem.elem('box').toString()}
        onChange={e => setContent(e.target.value)}
        value={content} placeholder={t('sessionCommentsInputPlaceholder')}
      />
      <Button
        className={bem.elem('btn').toString()}
        htmlType="submit"
        disabled={!content.length}
        type="primary"
        onClick={() => {
          if (shouldBeMailNotification()) {
            setStep('agreement-step');
          } else {
            setStep('info-step');
          }
        }}
      >
        {t('sessionCommentsBtnSend')}
      </Button>
    </div>
  );

  const agreement = (
    <Fragment>
      <div>
        <Checkbox
          value={isNotification}
          onChange={e => setIsNotification(e.target.checked)}
          className={bem.elem('checkbox').toString()}
        />
        <span>{t('sessionCommentsAgreementStep')}</span>
      </div>
      <div className={bem.elem('buttons').toString()}>
        <Button onClick={() => setStep('input-field-step')}>{t('sessionCommentsBtnBack')}</Button>
        <Button
          type='primary'
          onClick={() => setStep('info-step')}
        >
          {t('sessionCommentsBtnSave')}
        </Button>
      </div>
    </Fragment>
  );

  const info = (
    <Fragment>
      <div>{t('sessionCommentsAgreementStepInfo')}</div>
      <div className={bem.elem('buttons').toString()}>
        <Button onClick={() => {
          createComment({ content, status: 'new', sessionId, notification: isNotification });
          setContent('');
          setIsNotification(false);
          setStep('input-field-step');
        }}>
          {t('sessionCommentsBtnClose')}
        </Button>
      </div>
    </Fragment>
  );

  const inputContent = {
    'input-field-step': inputField,
    'agreement-step': agreement,
    'info-step': info,
  };

  return (
    <div className={bem.elem('container').toString()}>
      {inputContent[step]}
    </div>);
};

export { CommentsInput };

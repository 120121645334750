import React from 'react';
import { SPEAKERS } from 'src/constants/list';
import { usePersonState, usePersonDispatch } from 'src/store/persons/item/hooks';
import { useSpeakerListState, useSpeakerListDispatch } from 'src/store/speakers/list/hooks';
import { useSearchParamsState, useSearchParamsDispatch } from 'src/store/searchParams/hooks';
import { useSpeakerFiltersState } from 'src/store/speakers/filters/hooks';
import { useInitState } from 'src/store/init/hooks';
import { List } from 'src/view/components/List';
import { NewSpeakersItem } from 'src/view/pages/Speakers/NewItem';


const SpeakerList = () => {
  const { venueTimezoneOffset, timezones } = useInitState();
  const { speakerList, isSpeakerListLoading, speakerTotalCount } = useSpeakerListState();
  const { getSpeakerList } = useSpeakerListDispatch();
  const { setSearchParams } = useSearchParamsDispatch();
  const { speakerFilters } = useSpeakerFiltersState();
  const searchParams = useSearchParamsState();

  const { personSpeakerFavoriteIds, isPersonLoading } = usePersonState();
  const { updatePerson } = usePersonDispatch();

  return (
    <List
      listType={SPEAKERS}
      isLoading={isSpeakerListLoading || isPersonLoading}
      list={speakerList}
      allFilters={speakerFilters}
      getList={getSpeakerList}
      getIsFavorite={item => personSpeakerFavoriteIds.includes(item.id)}
      addToFavorites={updatePerson}
      removeFromFavorites={updatePerson}
      totalCount={speakerTotalCount}
      setSearchParams={setSearchParams}
      venueTimezoneOffset={venueTimezoneOffset}
      timezones={timezones}
      item={NewSpeakersItem}
      {...searchParams}
    />
  );
};

export { SpeakerList };

import React from 'react';
import { useSessionListState, useSessionListDispatch } from 'src/store/sessions/list/hooks';
import { useSearchParamsState, useSearchParamsDispatch } from 'src/store/searchParams/hooks';
import { useSessionFiltersState } from 'src/store/sessions/filters/hooks';
import { usePersonState, usePersonDispatch } from 'src/store/persons/item/hooks';
import { useFilesState } from 'src/store/files/hooks';
import { NewSessionItem } from 'src/view/pages/Sessions/NewItem';
import { List } from 'src/view/components/List';


const SessionList = () => {
  const { sessionList, isSessionListLoading, sessionTotalCount } = useSessionListState();
  const { getSessionsList } = useSessionListDispatch();
  const { setSearchParams } = useSearchParamsDispatch();
  const { sessionFilters } = useSessionFiltersState();
  const { isFileLoading } = useFilesState();
  const searchParams = useSearchParamsState();

  const { personSessionFavoriteIds, isPersonLoading } = usePersonState();
  const { updatePerson } = usePersonDispatch();

  const searchParamsList = { ...searchParams, viewMode: 'list' };

  return (
    <List
      isLoading={isSessionListLoading || isPersonLoading || isFileLoading}
      list={sessionList}
      allFilters={sessionFilters}
      getList={() => getSessionsList(searchParamsList)}
      getIsFavorite={item => personSessionFavoriteIds.includes(item.id)}
      addToFavorites={updatePerson}
      removeFromFavorites={updatePerson}
      totalCount={sessionTotalCount}
      setSearchParams={setSearchParams}
      item={NewSessionItem}
      {...searchParamsList}
    />
  );
};

export { SessionList };

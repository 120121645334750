import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSessionCommentsListDispatch, useSessionCommentsListState } from 'src/store/sessionComments/list/hooks';
import { useConfigs } from 'src/hooks/useConfigs';
import { CommentsBox } from 'src/view/components/CommentsBox';
import { Spinner } from 'src/view/components/Spinner';
import { useInitState } from 'src/store/init/hooks';

/**
 * Component for fetch data and render the list of session comments.
 *
 * @category Pages/Components
 */
const SessionComments = () => {
  const { isNEOSPlugin, sessionId } = useConfigs();
  const domElement = document.getElementById('op-session__comments');

  const { getSessionComments } = useSessionCommentsListDispatch();

  const { sessionCommentRefreshRate, isInitLoading } = useInitState();
  const { sessionComments, isSessionCommentsLoading, totalCount } = useSessionCommentsListState();

  useEffect(() => {
    if (sessionId) {
      getSessionComments(sessionId);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getSessionComments(sessionId);
    }, sessionCommentRefreshRate);

    return () => clearInterval(interval);
  }, []);

  const component = (
    <Spinner isLoading={isSessionCommentsLoading || isInitLoading}>
      <CommentsBox sessionComments={sessionComments} totalCount={totalCount} />
    </Spinner>
  );

  return isNEOSPlugin
    ? domElement && ReactDOM.createPortal(component, domElement)
    : component;
};

export { SessionComments };
